<template>
    <section>
        <div class="panel has-text-weight-semibold">
        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
            <div class="columns is-mobile">
                <div class="column is-size-6 has-text-centered">TANK INVENTORY</div>
            </div>
        </div>

        <div class="panel-block">
            <b-table class="control" 
                :data='records'
                :paginated="isPaginated"
                :per-page="perPage"
                paginationPosition="both"
                sortIcon="arrow-up"
                sortIconSize="is-small"
                default-sort=storeName
                defaultSortDirection='asc'
                detailed
                detail-key="storeName"
                >

            <template>
                <b-table-column field="storeName" label="Store" searchable sortable sorticon  v-slot="props">
                    {{ props.row.storeName }}
                </b-table-column>

                <b-table-column field="currentVolume" label="Address" v-slot="props">
                    {{ props.row.addressLine1 }}, {{ props.row.city }} {{ props.row.state }} {{ props.row.zipCode }}
                </b-table-column>
            </template>


            <template slot="detail" slot-scope="props">
              <b-table :data="props.row.platformTankInventoryDetailList">
                <template>
                    <b-table-column field="tankName" label="Tank"  sortable sorticon  v-slot="props">
                    {{ props.row.tankName }}
                    </b-table-column>

                    <b-table-column field="tankNumber" label="Last Update" sortable sorticon  v-slot="props">
                        {{ props.row.updatedAt }}
                    </b-table-column>

                    <b-table-column field="tankNumber" label="Product" sortable sorticon  v-slot="props">
                        {{ props.row.product }}
                    </b-table-column>

                    <b-table-column numeric field="capacity" label="Capacity" sortable sorticon  v-slot="props">
                        {{ props.row.capacity | formatFuelVolume }}
                    </b-table-column>

                    <b-table-column numeric field="currentVolume" label="Current Inventory" sortable sorticon  v-slot="props">
                        {{ props.row.currentVolume | formatFuelVolume }}
                    </b-table-column> 

                </template>
              </b-table>
            </template>


            <template slot="empty">
                <data-loading 
                    :isLoading="isLoading" 
                    :isFullPage="isFullPage"> 
                </data-loading>
            </template>

        </b-table>

        </div>
    </div>
    </section>
</template>

<script>
import UserStore from "../../../store/UserStore"

import DataLoading from '../../../components/app/DataLoading.vue'

import axios from 'axios'

export default {

    components: {
        DataLoading
    },

    metaInfo: {
        title: 'Tank Inventory'
    },

    data() {
        return {
            user: [],
            records: [],
            isPaginated: false,
            perPage: 20,
            isLoading: true,
            isFullPage: false,
            isShowDialog: false,
            action: '',
            storeId: 0,
            role: '',
            deleteAllowed: true,
            salesDateRange: [new Date(), new Date()],
            totalIncome: 0.00,
            showDetailIcon: true,
        }
    },

    methods: {

        fetchRecords() {

            if (this.user.activeStore) {

                var url = process.env.VUE_APP_API_ROOT_URL + 'platform/tanks/inventory'
                                
                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.records = response.data.data
                        if (this.records.length > this.perPage) {
                            this.isPaginated = true
                        }
                        this.isLoading = false
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                        this.isLoading = false
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    })
            }
            
        },
       
    },

    computed: {
        // getUser() {
        //     return UserStore.getters.getUser
        // },

        getActiveStore() {
            return UserStore.getters.getActiveStore
        },

    },

    watch: {

    },    

    mounted() {

        if (UserStore.getters.getActiveStore.role != 'PLATFORM_FUEL_DATA') {
            this.$router.push('/')
        }
        this.user = this.getUser()
        this.fetchRecords()

    }     

}
</script>

<style>
tr:nth-child(even) {
  background-color: #f2f2f2;
}

.btn-label-text {
    padding-left: 5px;
}
</style>